import axios, { AxiosResponse, AxiosError } from 'axios';
import Cookies from 'js-cookie';

// const token = Cookies.get(import.meta.env.VITE_COOKIE_SUFFIX as String);
console.log('import.meta.env.DEV >>', import.meta.env);

let token = window.crypto.randomUUID();
function getToken() {
  if (!window.location.pathname.includes('/share')) {
    token = Cookies.get(import.meta.env.VITE_COOKIE_SUFFIX as String) || token;
  }
  console.log('token >>', token);
  return token;
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_FELO_SUBTITLES_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': getToken(),
  },
});

instance.interceptors.request.use(
  (config) => {
    // 处理重复请求
    config.headers.Authorization = getToken();

    return config;
  },
  (error) => { 
    Promise.reject(error) 
  },
);

export const getProductList = () =>
  instance
    .request<API.Response<API.ProductListResponse>>({
      method: 'get',
      url: '/api/product/list',
    })
    .then((res) => {
      const { product_list, product_price_list, product_item_list } = res.data.data;

      return {
        ...res.data,
        data: product_list.map((product) => ({
          ...product,
          price_list: product_price_list.filter((price) => price.product_id === product.product_id),
          product_item: product_item_list.find((items) => items.product_id === product.product_id),
        })),
      };
    });

export const createOrder = (product_id: string, currency: string) =>
  instance.request<API.Response<string>>({
    method: 'POST',
    url: '/api/checkout',
    data: {
      cancel_url: `${window.location.origin}/pricing/?payment=failed`,
      currency,
      product_id,
      success_url: `${window.location.origin}/pricing/?payment=succeed`,
    },
  });

export const completeOrder = (checkout_id: string, receipt: string) =>
  instance.request<API.Response<unknown>>({
    method: 'PUT',
    url: `/api/checkout/${checkout_id}`,
    params: {
      receipt,
    },
  });

export const cancelOrder = (checkout_id: string) =>
  instance.request<API.Response<unknown>>({
    method: 'DELETE',
    url: `/api/checkout/${checkout_id}`,
  });

// 当前余额
export const getCurrentPlan = () =>
  instance.request<API.Response<API.PlanResponse>>({
    url: '/api/plan/current',
  });

// 当前购买/赠送余额
export const getPlanBalance = () =>
  instance.request<API.Response<API.PlanBalanceResponse>>({
    url: '/api/plan/balance',
  });
// 查询邀请信息
export const getInvitationInfo = () =>
  instance.request<API.Response<API.InviteInfo>>({
    url: '/api/user/invitation',
  });
// 购买记录
export const getPlanList = () =>
  instance.request<API.Response<API.PlanListResponse>>({
    url: '/api/plan/detail.list',
  });
// 购买记录
export const getBoughtProductList = (current: number, size: number, source?: string) =>
  instance.request<API.Response<API.BoughtProductResponse>>({
    url: '/api/user/product/page',
    params: {
      current,
      size,
    },
  });

// 使用记录
export const getUsageList = (current: number, size: number, start?: string, end?: string) =>
  instance.request<API.Response<API.DeductionResponse>>({
    url: '/api/deduction/page',
    params: {
      current,
      size,
    },
  });

// 用户设置
export const getUserConfig = (key?: string) =>
  instance
    .request<API.Response<API.UserConfigResponse>>({
      url: '/api/user/config',
      params: {
        key,
      },
    })
    .then((res) => {
      if (res.data.data) {
        Object.keys(res.data.data).forEach((k) => {
          // @ts-ignore
          res.data.data[k] = JSON.parse(res.data.data[k]).value;
        });
      }

      return res;
    });

// 修改字幕设置
export const updateUserConfig = (key: string, data: any) =>
  instance.request<API.Response<unknown>>({
    method: 'POST',
    url: '/api/user/config/set',
    data: {
      config_type: key,
      data: JSON.stringify(data),
    },
  });

// 用户字幕块查询
export const getLogDetail = (id: string) =>
  instance.request<API.Response<API.LogDetailResponse>>({
    url: `/api/user/subtitle/segment/${id}`,
  });

// 用户字幕块分页查询
export const getLogPage = (current: number, size: number) =>
  instance.request<API.Response<API.LogListResponse>>({
    url: '/api/user/subtitle/segment/page',
    params: {
      current,
      size,
    },
  });

// 导出字幕
export const downloadLog = (data: { user_subtitle_segment_id: string; zone: string }) =>
  instance.request<API.Response<string>>({
    method: 'PUT',
    url: '/api/user/subtitle/segment/export',
    data,
  });

// 删除字幕
export const deleteLog = (id: string) =>
  instance.request<API.Response<unknown>>({
    method: 'DELETE',
    url: `/api/user/subtitle/segment/${id}`,
  });

// 用户字幕标题修改
export const updateLogTitle = (data: { user_subtitle_segment_id: string; title: string }) =>
  instance.request<API.Response<unknown>>({
    method: 'PUT',
    url: '/api/user/subtitle/segment/title',
    data,
  });

// 更新字幕原文并重新翻译
export const updateOriginalText = (data: API.SegmentRetranslationParams) => (
  instance.request<API.Response<API.SegmentRetranslationResult>>({
    method: 'PUT',
    url: '/api/user/subtitle/segment/retranslation',
    data,
  })
);

// 创建用户反馈
export const createfeedback = (data: {
  app_id: string;
  app_sub_type: string;
  contact?: string;
  content: string;
  device_model?: string;
  images?: string[];
  os_version?: string;
  app_version?: string;
}) =>
  instance.request<API.Response<unknown>>({
    method: 'POST',
    url: '/api/user/feedback/create',
    data,
  });

// 获取用户签名
export const uploadImageSign = (data: { suffix: string }) =>
  instance.request<API.Response<any>>({
    method: 'POST',
    url: '/api/user/feedback/uploadImageSign',
    data,
  });

// 获取 字幕详情页面 MQTT 链接信息
export const getWebsiteMqttInfo = (room_id: string) => (
  instance.request<API.Response<API.WebsiteMqttInfoResponse>>({
    method: 'POST',
    url: '/api/connection/websiteInfo',
    data: {
      client_type: 'WEB',
      room_id,
    },
  })
);

// 包装函数
export const wrapRequest = async <T, P>(
  request: (params: P) => Promise<AxiosResponse<API.Response<T>>>,
  params: P
) => {
  try {
    const response = await request(params);
    return { code: 'succ', data: response.data };
  } catch (error) {
    // 处理异常
    const res: any = (error as AxiosError).response?.data;
    console.error('Request failed:', error);
    return {
      code: res.code ? (res.code as string) : 'fail',
      data: null,
    };
  }
};

// 获取keywords列表
export const fetchKeyWordList = () => (
  instance.request<API.Response<API.FetchKeyWordListResult>>({
    method: 'POST',
    url: '/api/user/noun/list',
  })
);

// Save：keywords列表
export const saveKeyWordList = (data: API.SaveKeyWordListParams) => (
  instance.request<API.Response<API.SaveKeyWordListResult>>({
    method: 'POST',
    url: '/api/user/noun/save',
    data,
  })
);

// Update：keywords列表
export const updateKeyWordList = (data: API.UpdateKeyWordListParams) => (
  instance.request<API.Response<API.UpdateKeyWordListResult>>({
    method: 'POST',
    url: '/api/user/noun/update',
    data,
  })
);
// Delete：keywords列表
export const deleteKeyWordList = (data: API.DelKeyWordListParams) => (
  instance.request<API.Response<API.DelKeyWordListResult>>({
    method: 'POST',
    url: '/api/user/noun/delete',
    data,
  })
);

// 获取分享房间ID
export const getShareRoomId = (userId: string | undefined) => (
  instance.request<API.Response<API.ShareRoomIdResponse>>({
    method: 'GET',
    url: '/api/user/share/query',
    params: {
      uid: userId,
    },
  })
);

// 获取分享连接
export const getShareConnect = (room_id: string) => (
  instance.request<API.Response<API.ShareConnectResponse>>({
    method: 'POST',
    url: '/api/connection/shareInfo',
    data: {
      room_id,
      client_type: 'WEB',
    },
  })
);

export const getSummary = (data: {
  user_subtitle_segment_id: string;
  target_lang: string;
}) => (
  instance.request<API.Response<API.SummaryResponse>>({
    method: 'POST',
    url: '/api/app/summary/create',
    data,
  })
);

// 获取历史
export const getHistory = (data: {
  user_subtitle_segment_id: string;
}) => (
  instance.request<API.Response<API.HistoryResponse>>({
    method: 'POST',
    url: '/api/app/summary/queryDetail',
    data,
  })
);

export default instance;
